import {isOffice} from './Office.js';

const nbsp = "\u00A0"

const carsharingStrings = {
  allgemeinSpeichern: "Speichern",
  allgemeinAbbrechen: "Abbrechen",
  allgemeinEditieren: "Editieren",
  allgemeinEntfernen: "Entfernen",
  allgemeinWiederherstellen: "Wiederherstellen",
  allgemeinZurück: "Zurück",
  allgemeinDrucken: "Drucken",
  buchenDatumsbereichWählen: "Datumsbereich wählen",
  buchenFahrzeug: "Fahrzeug",
  buchenFahrzeugNichtVerfügbar: "Das Fahrzeug ist zu der Zeit nicht verfügbar.",
  buchenKeinFahrzeugVerfügbar: "Zu der Zeit ist kein Fahrzeug verfügbar.",
  buchenStartWählen: "Start wählen.",
  buchenEndeWählen: "Ende wählen.",
  buchenFahrzeugWählen: "Fahrzeug wählen.",
  buchenStart: "Start",
  buchenEnde: "Ende",
  buchenDauer: "Dauer",
  buchenHinweis: "Hinweis",
  buchenBuchen: "Buchen",
  buchhaltungBearbeiten: "Fahrtenbuch bearbeiten",
  buchhaltungErstellen: "Fahrtenbuch anlegen",
  buchhaltungZuweisung: "Zuweisung",
  buchhaltungZuweisungswert: "Zuweisungswert",
  buchhaltungAufwendung: "Aufwendung",
  buchhaltungBetrag: "Betrag",
  buchhaltungDatum: "Datum",
  buchhaltungKostenstelle: "Kostenstelle",
  buchhaltungRabatt: "Rabatt",
  buchhaltungStartkilometer: "Startkilometer",
  buchhaltungEndkilometer: "Endkilometer",
  buchhaltungKommentar: "Kommentar",
  buchhaltungTypFahrt: "Fahrt",
  buchhaltungTypAusgabe: "Ausgabe",
  buchhaltungTypZuweisung: "Zuweisung",
  buchhaltungVorausfüllen: "Vorausfüllen",
  buchhaltungZeileHinzufügen: "Zeile hinzufügen",
  buchhaltungZeileEntfernen: "Zeile entfernen",
  fahrtenLaufendeBuchungen: "Laufende Buchungen",
  fahrtenZukünftigeBuchungen: "Zukünftige Buchungen",
  fahrtenAbgeschlosseneBuchungen: "Abgeschlossene Buchungen",
  fahrtenBeenden: "Beenden",
  fahrtenStornieren: "Stornieren",
  fahrtenBeendet: "Beendet",
  fahrtenStorniert: "Storniert",
  fuhrparkKilometerpreis: `Km-Preis bis 250${nbsp}km/Monat`,
  fuhrparkLangstreckenpreis: `Km-Preis ab 250${nbsp}km/Monat`,
  fuhrparkKontrollschild: "Kontrollschild",
  fuhrparkInhaber: "Inhaber",
  fuhrparkTelefonInhaber: "Telefon Inhaber",
  fuhrparkHaftpflicht: "Haftpflicht",
  fuhrparkSelbstbehaltHaftpflicht: "Selbstbehalt Haftpflicht",
  fuhrparkSelbstbehaltHaftpflichtJunglenker: "Selbstbehalt Haftpflicht Junglenker",
  fuhrparkVollkasko: "Vollkasko",
  fuhrparkSelbstbehaltVollkasko: "Selbstbehalt Vollkasko",
  fuhrparkTreibstoff: "Treibstoff",
  fuhrparkMotoröl: "Motoröl",
  fuhrparkPannenhilfe: "Pannenhilfe",
  fuhrparkTelefonPannenhilfe: "Telefon Pannenhilfe",
  fuhrparkExtras: "Extras",
  fuhrparkMängel: "Mängel",
  fuhrparkBuchungshinweis: "Buchungshinweis",
  fuhrparkFahrzeugName: "Fahrzeugname",
  fuhrparkNeuesFahrzeug: "Neues Fahrzeug",
  fuhrparkFahrzeugkosten: "Kosten",
  fuhrparkFahrzeugBearbeiten: "Fahrzeug bearbeiten",
  fuhrparkKostenPlaceholder: "Aufwendung",
  fuhrparkBetragPlaceholder: "Betrag [CHF]",
  fuhrparkKostenHinzufügen: "Hinzufügen",
  fuhrparkFahrzeugLöschen: "Fahrzeug löschen",
  fuhrparkGelöschteFahrzeuge: "Gelöschte Fahrzeuge",
  fuhrparkVoltage: "Aktuelle Batteriespannung",
  abrechnungDatum: "Datum",
  abrechnungAbrechnungsname: "Abrechnungsname",
  abrechnungNeueAbrechnung: "Neue Abrechnung",
  abrechnungAbrechnungLöschen: "Abrechnung löschen",
  abrechnungAbrechnungErstellen: "Abrechnung erstellen",
  abrechnungAbrechnungAnzeigen: "Abrechnung anzeigen",
  abrechnungBilanzAnzeigen: "Bilanz anzeigen",
  abrechnungAbrechnungBearbeiten: "Abrechnung bearbeiten",
  abrechnungGelöschteAbrechnungen: "Gelöschte Abrechnungen",
  abrechnungFahrkostenabrechnung: "Fahrkostenabrechnung",
  abrechnungBilanz: "Bilanz",
  kostenstellenKostenstellenBearbeiten: "Kostenstellen bearbeiten",
  kostenstellenKostenstellePlaceholder: "Kostenstelle",
  kostenstellenBenutzername: "Benutzername",
  kostenstellenKostenstelle: "Kostenstelle",
  loginCarsharing: "Carsharing",
  loginName: "Name",
  loginZugangscode: "Zugangscode",
  loginAnmelden: "Anmelden",
  tabBuchen: "Buchen",
  tabFahrten: "Übersicht",
  tabFuhrpark: "Fuhrpark",
  tabAbrechnung: "Abrechnung",
  tabLogout: "Logout",
  fehlerLogin: "Es ist ein Fehler beim Login aufgetreten.",
  fehlerLaden: "Es ist ein Fehler beim Laden der Seite aufgetreten.",
  fehlerBuchen: "Es ist ein Fehler beim Eintragen der Buchung aufgetreten.",
  fehlerStornieren: "Es ist ein Fehler beim Stornieren der Buchung aufgetreten.",
  fehlerFahrzeugAnlegen: "Es ist ein Fehler beim Anlegen des neuen Fahrzeugs aufgetreten.",
  fehlerFahrzeugLöschen: "Es ist ein Fehler beim Löschen des neuen Fahrzeugs aufgetreten.",
  fehlerFahrzeugWiederherstellen: "Es ist ein Fehler beim Wiederherstellen des Fahrzeugs aufgetreten.",
  fehlerFahrzeugEditieren: "Es ist ein Fehler beim Editieren des Fahrzeugs aufgetreten.",
  fehlerKostenstelleAnlegen: "Es ist ein Fehler beim Anlegen der Kostenstelle aufgetreten.",
  fehlerKostenstelleEditieren: "Es ist ein Fehler beim Editieren der Kostenstelle aufgetreten.",
  fehlerKostenAnlegen: "Es ist ein Fehler beim Anlegen der Kosten aufgetreten.",
  fehlerKostenLöschen: "Es ist ein Fehler beim Löschen der Kosten aufgetreten.",
  fehlerAbrechnungAnlegen: "Es ist ein Fehler beim Anlegen der Abrechnung aufgetreten.",
  fehlerAbrechnungLöschen: "Es ist ein Fehler beim Löschen der Abrechnung aufgetreten.",
  fehlerAbrechnungAuswerten: "Es ist ein Fehler beim Auswerten der Abrechnung aufgetreten.",
  fehlerBuchhaltungEdited: "Es ist ein Fehler beim Editieren der Buchhaltung aufgetreten.",
  fehlerAbrechnungEdited: "Es ist ein Fehler beim Editieren der Abrechnung aufgetreten."
}

const officeStrings = {
  loginCarsharing: "Büroteilete",
  tabFuhrpark: "Räume",
  buchenFahrzeug: "Büro",
  buchenFahrzeugWählen: "Büro wählen.",
  buchenFahrzeugNichtVerfügbar: "Das Büro ist zu der Zeit nicht verfügbar.",
  buchenKeinFahrzeugVerfügbar: "Zu der Zeit ist kein Büro verfügbar.",
}

export const strings = (isOffice()
    ? Object.assign({}, carsharingStrings, officeStrings)
    : carsharingStrings)
