export class CollectionModel {
  constructor(httpSession, httpAddress, parameters="") {
    this.address = httpAddress
    this.session = httpSession
    this.parameters = parameters
  }

  async get() {
    return await this.session.get(this.address + this.parameters)
  }

  async create(buchung) {
    return await this.session.post(this.address + this.parameters, buchung)
  }

  async updateId(id, update) {
    return await this.session.put(`${this.address}/${id}${this.parameters}`, update)
  }

  async deleteId(id) {
    return await this.session.deleteItem(`${this.address}/${id}${this.parameters}`)
  }
}
