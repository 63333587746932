import React, {useState} from 'react'
import Collapsible from 'react-collapsible'
import {isAdmin} from './Authorization.js'
import {formatPreis, twoDigits, LineEdit, PriceEdit, TextEdit} from './EditControls.js'
import {strings} from './Localization.js'
import './Fuhrpark.css'

export function Fuhrpark(props) {
  return (
      <div className="fuhrpark">
        { props.fahrzeuge.filter(fahrzeug => fahrzeug.gelöscht === null)
          .map((fahrzeug) => renderFahrzeug(props, fahrzeug)) }
        { isAdmin() &&
          <FuhrparkBearbeiten
            fahrzeuge={props.fahrzeuge}
            neuesFahrzeugClicked={props.neuesFahrzeugClicked}
            fahrzeugWiederherstellenClicked={props.fahrzeugWiederherstellenClicked}/> }
      </div>)
}

function renderFahrzeug(props, fahrzeug) {
  return (
      <div key={fahrzeug.id}>
        <h2>{fahrzeug.name}</h2>
        <div className="show-line-layout">
          <Property
	          title={strings.fuhrparkInhaber}
            value={fahrzeug.inhaber}/>
          <Property
	          title={strings.fuhrparkTelefonInhaber}
            value={fahrzeug.telefonInhaber}/>
        </div>
        <div className="show-line-layout">
          <Property
	          title={strings.fuhrparkKontrollschild}
            value={fahrzeug.kontrollschild}/>
        </div>
        <div className="show-line-layout">
          <Property
	          title={strings.fuhrparkHaftpflicht}
            value={fahrzeug.haftpflicht}/>
          <Property
	          title={strings.fuhrparkSelbstbehaltHaftpflicht}
            value={formatPreis(fahrzeug.selbstbehaltHaftpflicht)}/>
        </div>
        <div className="show-line-layout">
          <EmptyProperty/>
          <Property
	          title={strings.fuhrparkSelbstbehaltHaftpflichtJunglenker}
            value={formatPreis(fahrzeug.selbstbehaltHaftpflichtJunglenker)}/>
        </div>
        <div className="show-line-layout">
          <Property
	          title={strings.fuhrparkVollkasko}
            value={fahrzeug.vollkasko}/>
          <Property
	          title={strings.fuhrparkSelbstbehaltVollkasko}
            value={formatPreis(fahrzeug.selbstbehaltVollkasko)}/>
        </div>
        <div className="show-line-layout">
          <Property
	          title={strings.fuhrparkPannenhilfe}
            value={fahrzeug.pannenhilfe}/>
          <Property
	          title={strings.fuhrparkTelefonPannenhilfe}
            value={fahrzeug.telefonPannenhilfe}/>
        </div>
        <div className="show-line-layout">
          <Property
	          title={strings.fuhrparkTreibstoff}
            value={fahrzeug.treibstoff}/>
          <Property
	          title={strings.fuhrparkMotoröl}
            value={fahrzeug.motoröl}/>
        </div>
        <div className="show-line-layout">
          <Property
	          title={strings.fuhrparkKilometerpreis}
            value={formatPreis(fahrzeug.kilometerpreis)}/>
          <Property
	          title={strings.fuhrparkLangstreckenpreis}
            value={formatPreis(fahrzeug.langstreckenpreis)}/>
        </div>
        <div className="show-line-layout">
          <Property
	          title={strings.fuhrparkExtras}
            value={fahrzeug.extras}/>
          <Property
	          title={strings.fuhrparkMängel}
            value={fahrzeug.mängel}/>
        </div>
        { fahrzeug.buchungshinweis &&
          <div className="horizontal-layout">
            <Property
	            title={strings.fuhrparkBuchungshinweis}
              value={fahrzeug.buchungshinweis}/>
          </div>}
        { fahrzeug.voltage &&
          <div className="horizontal-layout">
            <Property
	            title={strings.fuhrparkVoltage}
              value={fahrzeug.voltage.toString() + " V"}/>
          </div>}
        <Collapsible
          className="fahrzeug-kosten"
          openedClassName="fahrzeug-kosten"
          trigger={strings.fuhrparkFahrzeugkosten}>
          <Fahrzeugkosten
            kosten={props.kosten}
            fahrzeug={fahrzeug}
            username={props.username}
            neueKostenClicked={props.neueKostenClicked}
            kostenLöschenClicked={props.kostenLöschenClicked}/>
        </Collapsible>
        { isAdmin() && renderFahrzeugBearbeiten(props, fahrzeug) }
      </div>)
}

function Fahrzeugkosten(props) {
  const [name, setName] = useState("")
  const [betrag, setBetrag] = useState("")
  const onNeueKostenClicked = (e) => {
    e.preventDefault()
    props.neueKostenClicked({
      name: name,
      preis: betrag,
      fahrzeugId: props.fahrzeug.id,
      username: props.username,
      datum: new Date().toISOString().split('T')[0]})
    setName("")
    setBetrag("")
  }
  return (
    <div className="kosten">
      <form onSubmit={onNeueKostenClicked}>
        <input
          className="carsharing-input"
          required
          type="text"
          placeholder={strings.fuhrparkKostenPlaceholder}
          value={name}
          onChange={(e) => { setName(e.target.value)}}/>
        <input
          className="carsharing-input"
          required
          type="number"
          step=".01"
          pattern={twoDigits}
          placeholder={strings.fuhrparkBetragPlaceholder}
          value={betrag}
          onChange={(e) => { setBetrag(e.target.value)}}
          onWheel={e => e.target.blur()}/>
        <button
          className="highlighted"
          type="submit">
          {strings.fuhrparkKostenHinzufügen}
        </button>
      </form>
      { props.kosten
          .filter(ausgabe => { return ausgabe.fahrzeugId === props.fahrzeug.id && !ausgabe.gelöscht})
          .sort((a, b) => { return a.datum > b.datum ? -1 : 1 })
          .map((ausgabe) => { return renderAusgabe(props, ausgabe)})}
    </div>)
}

function renderAusgabe(props, ausgabe) {
  return (
    <div
      key={ausgabe.id}
      className="ausgabe">
      <div className="name"><b>{ausgabe.name}</b></div>
      <div className="preis">{formatPreis(ausgabe.preis)}</div>
      <div className="datum">{ausgabe.datum}</div>
      <div className="username">{ausgabe.username}</div>
      <button
        type="button"
        onClick={() => props.kostenLöschenClicked(ausgabe.id)}>
        {strings.allgemeinEntfernen}
      </button>
    </div>)
}

function renderFahrzeugBearbeiten(props, fahrzeug) {
  return (
    <Collapsible
      className="fahrzeug-bearbeiten"
      openedClassName="fahrzeug-bearbeiten"
      trigger={strings.fuhrparkFahrzeugBearbeiten}>
      <button
        type="button"
        onClick={() => props.fahrzeugLöschenClicked(fahrzeug.id)}>
        {strings.fuhrparkFahrzeugLöschen}
      </button>
      <LineEdit
        value={fahrzeug.inhaber}
	      title={strings.fuhrparkInhaber}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "inhaber", value)}}/>
      <LineEdit
        value={fahrzeug.telefonInhaber}
	      title={strings.fuhrparkTelefonInhaber}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "telefonInhaber", value)}}/>
      <LineEdit
        value={fahrzeug.kontrollschild}
	      title={strings.fuhrparkKontrollschild}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "kontrollschild", value)}}/>
      <LineEdit
        value={fahrzeug.pannenhilfe}
	      title={strings.fuhrparkPannenhilfe}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "pannenhilfe", value)}}/>
      <LineEdit
        value={fahrzeug.telefonPannenhilfe}
	      title={strings.fuhrparkTelefonPannenhilfe}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "telefonPannenhilfe", value)}}/>
      <LineEdit
        value={fahrzeug.haftpflicht}
	      title={strings.fuhrparkHaftpflicht}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "haftpflicht", value)}}/>
      <PriceEdit
        value={fahrzeug.selbstbehaltHaftpflicht}
	      title={strings.fuhrparkSelbstbehaltHaftpflicht}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "selbstbehaltHaftpflicht", value)}}/>
      <PriceEdit
        value={fahrzeug.selbstbehaltHaftpflichtJunglenker}
	      title={strings.fuhrparkSelbstbehaltHaftpflichtJunglenker}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "selbstbehaltHaftpflichtJunglenker", value)}}/>
      <LineEdit
        value={fahrzeug.vollkasko}
	      title={strings.fuhrparkVollkasko}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "vollkasko", value)}}/>
      <PriceEdit
        value={fahrzeug.selbstbehaltVollkasko}
	      title={strings.fuhrparkSelbstbehaltVollkasko}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "selbstbehaltVollkasko", value)}}/>
      <LineEdit
        value={fahrzeug.treibstoff}
	      title={strings.fuhrparkTreibstoff}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "treibstoff", value)}}/>
      <LineEdit
        value={fahrzeug.motoröl}
	      title={strings.fuhrparkMotoröl}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "motoröl", value)}}/>
      <PriceEdit
        value={fahrzeug.kilometerpreis}
	      title={strings.fuhrparkKilometerpreis}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "kilometerpreis", value)}}/>
      <PriceEdit
        value={fahrzeug.langstreckenpreis}
	      title={strings.fuhrparkLangstreckenpreis}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "langstreckenpreis", value)}}/>
      <TextEdit
        value={fahrzeug.extras}
	      title={strings.fuhrparkExtras}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "extras", value)}}/>
      <TextEdit
        value={fahrzeug.mängel}
	      title={strings.fuhrparkMängel}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "mängel", value)}}/>
      <TextEdit
        value={fahrzeug.buchungshinweis}
	      title={strings.fuhrparkBuchungshinweis}
        onSave={(value) => {props.fahrzeugEdited(fahrzeug.id, "buchungshinweis", value)}}/>
    </Collapsible>)
}

function FuhrparkBearbeiten(props) {
  const [name, setName] = useState("")
  const onNeuesFahrzeugClicked = (e) => {
    e.preventDefault()
    props.neuesFahrzeugClicked(name)
    setName("")
  }
  return (
    <>
      <form
        className="neues-fahrzeug"
        onSubmit={onNeuesFahrzeugClicked}>
        <input
          className="carsharing-input"
          required
          type="text"
          placeholder={strings.fuhrparkFahrzeugName}
          value={name}
          onChange={(e) => { setName(e.target.value)}}/>
        <button
          className="highlighted"
          type="submit">
          {strings.fuhrparkNeuesFahrzeug}
        </button>
      </form>
      <Collapsible
        className="gelöschte-fahrzeuge"
        openedClassName="gelöschte-fahrzeuge"
        trigger={strings.fuhrparkGelöschteFahrzeuge}>
        { props.fahrzeuge.filter(fahrzeug => fahrzeug.gelöscht !== null)
          .map((fahrzeug) => renderGelöschtesFahrzeug(props, fahrzeug)) }
      </Collapsible>
    </>)
}

function renderGelöschtesFahrzeug(props, fahrzeug) {
  return (
      <div key={fahrzeug.id}>
        <span>{fahrzeug.name}</span>
        <button
          type="button"
          onClick={() => props.fahrzeugWiederherstellenClicked(fahrzeug.id)}>
          {strings.allgemeinWiederherstellen}
        </button>
      </div>)
}

function Property(props) {
  const text = props.value ? props.value : "-"
  return (
    <div className="show-line">
      <span className="show-line-key">{props.title}</span>
      <div className="show-line-value">
        { text
            .split('\n')
            .map((line, index) => { return <span key={index}>{line}</span> }) }
      </div>
    </div>)
}

function EmptyProperty(props) {
  return (
    <div className="show-line empty">
      <span className="show-line-key"/>
      <span className="show-line-value"/>
    </div>)
}
