import React, {useCallback, useState} from 'react';
import {renderChevronLeft, renderChevronRight} from './Chevron.js'
import * as Format from './Format.js'
import {isOffice} from './Office.js'
import {strings} from './Localization.js'
import TimeRangePicker from './TimeRangePicker.js';
import './Buchen.css';

export function Buchen(props) {
  const selectionDefaultCount = 7
  const selectionStart = props.currentDateTime
  const selectionEnd = TimeRangePicker.datePlusDays(selectionStart, selectionDefaultCount - 1)
  
  const [timeRangeStart, setTimeRangeStart] = useState(selectionStart)
  const [timeRangeEnd, setTimeRangeEnd] = useState(selectionEnd)
  const [fahrzeugId, setFahrzeugId] = useState(null)
  const [buchungStart, setBuchungStart] = useState(null)
  const [buchungEnd, setBuchungEnd] = useState(null)
                
  const auswahlGebucht = useCallback((id = fahrzeugId) => { return fahrzeugGebucht(
    {"start": buchungStart, "end": buchungEnd},
    props.buchungen,
    id)}, [buchungStart, buchungEnd, fahrzeugId, props.buchungen])

  const auswahlVonAllenGebucht = alleFahrzeugeGebucht(
    {"start": buchungStart, "end": buchungEnd},
    props.buchungen,
    props.fahrzeuge)

  const fahrzeugName = props.fahrzeuge.find(fahrzeug => fahrzeug.id === fahrzeugId)?.name
  const kilometerpreis = props.fahrzeuge.find(fahrzeug => fahrzeug.id === fahrzeugId)?.kilometerpreis
  const langstreckenpreis = props.fahrzeuge.find(fahrzeug => fahrzeug.id === fahrzeugId)?.langstreckenpreis
  const buchungshinweis = props.fahrzeuge.find(fahrzeug => fahrzeug.id === fahrzeugId)?.buchungshinweis
  
  const datesChanged = useCallback((dates) => {
    const [start, end] = dates
    setTimeRangeStart(start)
    setTimeRangeEnd(end)
  }, [])

  const buchungszeitChanged = useCallback((dates) => {
    const [start, end] = dates;
    setBuchungStart(start)
    setBuchungEnd(end)
  }, [])

  const buchenClicked = useCallback(async () => {
    await props.onSubmit({
      start: buchungStart.toISOString(),
      ende: buchungEnd.toISOString(),
      fahrzeugId: fahrzeugId})
  }, [props, buchungStart, buchungEnd, fahrzeugId])
  
  return (
      <div className="Buchen">
        <div className="vertical-layout">
          <TimeRangePicker
            currentDateTime={props.currentDateTime}
            isReserved={(range) => { return fahrzeugId
	      ? fahrzeugGebucht(range, props.buchungen, fahrzeugId)
              : alleFahrzeugeGebucht(range, props.buchungen, props.fahrzeuge)}}
            startDate={timeRangeStart}
            endDate={timeRangeEnd}
            onChange={buchungszeitChanged}/>

          <div className="horizontal-layout date-selection">
            <div className="horizontal-layout">
              <div className="horizontal-layout date-selection-arrows">
                { renderChevronLeft(() => {datesChanged([
                  TimeRangePicker.datePlusDays(timeRangeStart, -1),
                  TimeRangePicker.datePlusDays(timeRangeEnd, -1)])}) }
                <span>1T</span>
                { renderChevronRight(() => {datesChanged([
                  TimeRangePicker.datePlusDays(timeRangeStart, 1),
                  TimeRangePicker.datePlusDays(timeRangeEnd, 1)])}) }
              </div>
              <div className="horizontal-layout date-selection-arrows">
                { renderChevronLeft(() => {datesChanged([
                  TimeRangePicker.datePlusDays(timeRangeStart, -7),
                  TimeRangePicker.datePlusDays(timeRangeEnd, -7)])}) }
                <span>7T</span>
                { renderChevronRight(() => {datesChanged([
                  TimeRangePicker.datePlusDays(timeRangeStart, 7),
                  TimeRangePicker.datePlusDays(timeRangeEnd, 7)])}) }
              </div>
            </div>
          </div>
        </div>

        <div className="vertical-layout">
          <div className="fahrzeug-auswahl vertical-layout">
            <span
              className="title">
              {strings.buchenFahrzeug}
            </span>
            <div className="selection-box">
              { props.fahrzeuge.map(fahrzeug =>
                  <div
                    key={fahrzeug.id}
                    className={ fahrzeugId === fahrzeug.id ? "item selected-item" : "item"}
                    onClick={() => { setFahrzeugId(fahrzeugId !== fahrzeug.id ? fahrzeug.id : null) }}>

                    <div className={auswahlGebucht(fahrzeug.id) ? "verfügbarkeit gebucht" : "verfügbarkeit"}/>
                    <span>{fahrzeug.name}</span>
                  </div>) }
            </div>
          </div>
          <div className="vertical-space"/>

          <div className="buchung-zusammenfassung">
            <div className={!buchungStart ? "info" : "hidden"}>
              <div>{strings.buchenStartWählen}</div>
            </div>
            <div className={buchungStart && !buchungEnd ? "info" : "hidden"}>
              <div>{strings.buchenEndeWählen}</div>
            </div>
            <div className={auswahlVonAllenGebucht ? "info" : "hidden"}>
              <div>{strings.buchenKeinFahrzeugVerfügbar}</div>
            </div>
            <div className={auswahlGebucht() && !auswahlVonAllenGebucht ? "info" : "hidden"}>
              <div>{strings.buchenFahrzeugNichtVerfügbar}</div>
            </div>
            <div className={buchungStart && buchungEnd && !auswahlVonAllenGebucht && !fahrzeugId ? "info" : "hidden"}>
              <div>{strings.buchenFahrzeugWählen}</div>
            </div>
            <button
              className={"highlighted " + (
                  buchungStart && buchungEnd && fahrzeugId && !auswahlGebucht()
                  ? ""
                  : "hidden")}
              type="button"
              onClick={buchenClicked}>
              {strings.buchenBuchen}
            </button>
            <table>
              <tbody>
                <tr>
                  <th>{strings.buchenStart}</th>
                  <td>{Format.date(buchungStart)}</td>
                </tr>
                <tr>
                  <th>{strings.buchenEnde}</th>
                  <td>{Format.endDate(buchungEnd)}</td>
                </tr>
                <tr>
                  <th>{strings.buchenDauer}</th>
                  <td>{Format.duration(buchungStart, buchungEnd)}</td>
                </tr>
                <tr>
                  <th>{strings.buchenFahrzeug}</th>
                  <td>{fahrzeugId ? fahrzeugName : "-"}</td>
                </tr>
                { !isOffice() && 
                  <tr>
                    <th>{strings.fuhrparkKilometerpreis}</th>
                    <td>{fahrzeugId ? "CHF " + kilometerpreis?.toFixed(2) : "-"}</td>
                  </tr>
                }
                { !isOffice() && 
                  <tr>
                    <th>{strings.fuhrparkLangstreckenpreis}</th>
                    <td>{fahrzeugId ? "CHF " + langstreckenpreis?.toFixed(2) : "-"}</td>
                  </tr>
                }
                {buchungshinweis &&
                  <tr>
                    <th>{strings.buchenHinweis}</th>
                    <td className="preline">{buchungshinweis}</td>
                  </tr>}
              </tbody>
            </table>
          </div>
        </div>
      </div>)
}

function alleFahrzeugeGebucht(range, buchungen, fahrzeuge) {
  return fahrzeuge.every(fahrzeug => fahrzeugGebucht(range, buchungen, fahrzeug.id))
}
  
function fahrzeugGebucht(range, buchungen, fahrzeugId) {
  return !buchungen
    .filter(buchung => buchung.fahrzeugId === fahrzeugId)
    .map(buchung => ({
          "start": new Date(buchung.start),
          "end": new Date(buchung.ende)}))
    .every(buchung => !rangesOverlap(range, buchung))
}
  
function rangesOverlap(range0, range1) {
  return range0.start < range1.end && range1.start < range0.end
}
