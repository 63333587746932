import React, {useCallback, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs'
import {AiOutlineCalendar, AiOutlineCar, AiOutlineLogout} from 'react-icons/ai'
import {TbReportMoney, TbRoad} from 'react-icons/tb'
import {BsDoorOpen} from "react-icons/bs";
import {isAdmin} from './Authorization.js'
import {Abrechnungen} from './Abrechnung.js'
import {Buchen} from './Buchen.js'
import {Fahrten} from './Fahrten.js'
import {Fuhrpark} from './Fuhrpark.js'
import {strings} from './Localization.js'
import {isOffice} from './Office.js'
import {useInterval} from './UseInterval.js'
import {useSocket} from './UseSocket.js'
import 'react-tabs/style/react-tabs.css'
import './Carsharing.css'

const buchenIndex = 0
const fahrtenIndex = 1
//not used: const fuhrparkIndex = 2
//not used: const abrechnungsIndex = 3
const logoutIndex = 4

export function Carsharing(props) {
  const logout = props.onLogout
  const backend = props.backend
  const [tabIndex, setTabIndex] = useState(buchenIndex)
  const [initialized, setInitialized] = useState(false)
  const dateTime = useQuery("dateTime", async () => await backend.getDateTime(), {retry: 0})
  const buchungen = useQuery("buchungen", async () => await backend.buchungen.get(), {retry: 0})
  const fahrzeuge = useQuery("fahrzeuge", async () => await backend.fahrzeuge.get(), {retry: 0})
  const kosten = useQuery("kosten", async () => await backend.kosten.get(), {retry: 0})
  const kostenstellen = useQuery("kostenstellen", async () => await backend.kostenstellen.get(), {retry: 0})
  const abrechnungen = useQuery("abrechnungen", async () => await backend.abrechnungen.get(), {retry: 0})
  const buchhaltung = useQuery("buchhaltung", async () => await backend.buchhaltung.get(), {retry: 0})

  useSocket(backend.socket, 'buchungen', () => {
    props.queryClient.invalidateQueries("buchungen")
  })
  useSocket(backend.socket, 'fahrzeuge', () => {
    props.queryClient.invalidateQueries("fahrzeuge")
  })
  useSocket(backend.socket, 'kosten', () => {
    props.queryClient.invalidateQueries("kosten")
  })
  useSocket(backend.socket, 'kostenstellen', () => {
    props.queryClient.invalidateQueries("kostenstellen")
  })
  useSocket(backend.socket, 'abrechnungen', () => {
    props.queryClient.invalidateQueries("abrechnungen")
  })
  useSocket(backend.socket, 'buchhaltung', () => {
    props.queryClient.invalidateQueries("buchhaltung")
  })
    
  useInterval(() => { 
    props.queryClient.invalidateQueries("dateTime")
  }, 60 * 1000)

  useEffect(() => {
    if (dateTime.isSuccess
      && buchungen.isSuccess
      && fahrzeuge.isSuccess
      && kosten.isSuccess
      && kostenstellen.isSuccess
      && abrechnungen.isSuccess
      && buchhaltung.isSuccess) {
      setInitialized(true)
    }
  }, [setInitialized, dateTime.isSuccess, buchungen.isSuccess, fahrzeuge.isSuccess,
    kosten.isSuccess, kostenstellen.isSuccess, abrechnungen.isSuccess, buchhaltung.isSuccess])

  useEffect(() => {
    if (dateTime.isError
      || buchungen.isError
      || fahrzeuge.isError
      || kosten.isError
      || kostenstellen.isError
      || abrechnungen.isError
      || buchhaltung.isError) {
      if (!initialized) {
        console.warn(strings.fehlerLaden)
        logout()
      } else {
        console.warn("Es ist ein Fehler beim Aktualisieren der Seite aufgetreten.")
      }
    }
  }, [initialized, logout, props, dateTime.isError, buchungen.isError, fahrzeuge.isError,
    kosten.isError, kostenstellen.isError, abrechnungen.isError, buchhaltung.isError])

  const tabIndexSelected = useCallback(index => {
    if (index !== logoutIndex) {
      setTabIndex(index)
    }
    else {
      logout()
    }
  }, [setTabIndex, logout])

  const buchungSubmitted = useCallback(async buchung => {
    try {
      buchung.username = props.username
      await backend.buchungen.create(buchung)
      props.queryClient.invalidateQueries("buchungen")
      setTabIndex(fahrtenIndex)
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerBuchen)
    }
  }, [props, backend])

  const stornierenClicked = useCallback(async buchungsId => {
    try {
      await backend.buchungen.deleteId(buchungsId)
      props.queryClient.invalidateQueries("buchungen")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerStornieren)
    }
  }, [props, backend])

  const neuesFahrzeugClicked = useCallback(async fahrzeugName => {
    try {
      await backend.fahrzeuge.create({
        name: fahrzeugName,
        type: (isOffice() ? "office" : "vehicle")
      })
      props.queryClient.invalidateQueries("fahrzeuge")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerFahrzeugAnlegen)
    }
  }, [props, backend])

  const fahrzeugLöschenClicked = useCallback(async fahrzeugId => {
    try {
      await backend.fahrzeuge.deleteId(fahrzeugId)
      props.queryClient.invalidateQueries("fahrzeuge")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerFahrzeugLöschen)
    }
  }, [props, backend])

  const fahrzeugWiederherstellenClicked = useCallback(async fahrzeugId => {
    try {
      let fahrzeug = fahrzeuge.data.find(fahrzeug => fahrzeug.id === fahrzeugId)
      fahrzeug.gelöscht = null
      await backend.fahrzeuge.updateId(fahrzeugId, fahrzeug)
      props.queryClient.invalidateQueries("fahrzeuge")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerFahrzeugWiederherstellen)
    }
  }, [props, backend, fahrzeuge])

  const fahrzeugEdited = useCallback(async (fahrzeugId, key, value) => {
    try {
      let fahrzeug = fahrzeuge.data.find(fahrzeug => fahrzeug.id === fahrzeugId)
      fahrzeug[key] = value
      await backend.fahrzeuge.updateId(fahrzeugId, fahrzeug)
      props.queryClient.invalidateQueries("fahrzeuge")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerFahrzeugEditieren)
    }
  }, [props, backend, fahrzeuge])

  const neueKostenClicked = useCallback(async ausgabe => {
    try {
      await backend.kosten.create(ausgabe)
      props.queryClient.invalidateQueries("kosten")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerKostenAnlegen)
    }
  }, [props, backend])

  const kostenLöschenClicked = useCallback(async ausgabeId => {
    try {
      await backend.kosten.deleteId(ausgabeId)
      props.queryClient.invalidateQueries("kosten")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerKostenLöschen)
    }
  }, [props, backend])
  
  const neueAbrechnungClicked = useCallback(async abrechnungsName => {
    try {
      const abrechnung = await backend.abrechnungen.create({name: abrechnungsName, datum: dateTime.data.split('T')[0]})
      await fahrzeuge.data
        .filter(fahrzeug => !fahrzeug.gelöscht)
        .forEach(async fahrzeug => {
        await backend.buchhaltung.create({
          abrechnungsId: abrechnung.id,
          fahrzeugId: fahrzeug.id})
      })
      props.queryClient.invalidateQueries("abrechnungen")
      props.queryClient.invalidateQueries("buchhaltung")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerAbrechnungAnlegen)
    }
  }, [props, backend, fahrzeuge, dateTime.data])

  const neueKostenstelleClicked = useCallback(async (username, kostenstelle) => {
    try {
      await backend.kostenstellen.create({"username": username, "kostenstelle": kostenstelle})
      props.queryClient.invalidateQueries("kostenstelle")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerKostenstelleAnlegen)
    }
  }, [props, backend])

  const kostenstelleEdited = useCallback(async (kostenstellenId, value) => {
    try {
      let update = kostenstellen.data.find(kostenstelle => kostenstelle.id === kostenstellenId)
      update["kostenstelle"] = value
      await backend.kostenstellen.updateId(kostenstellenId, update)
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerKostenstelleEditieren)
    }
  }, [props, backend, kostenstellen])

  const abrechnungLöschenClicked = useCallback(async abrechnungsId => {
    try {
      await backend.abrechnungen.deleteId(abrechnungsId)
      props.queryClient.invalidateQueries("abrechnung")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerAbrechnungLöschen)
    }
  }, [props, backend])
  
  const abrechnungErstellenClicked = useCallback(async abrechnungsId => {
    try {
      const result = await backend.abrechnungAuswerten(abrechnungsId)
      if (result.error) {
        props.onError(strings.fehlerAbrechnungAuswerten + " " + result.error)
        console.error(result.detail)
      }
      props.queryClient.invalidateQueries("abrechnung")
      return result
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerAbrechnungAuswerten)
    }
  }, [props, backend])
  
  const abrechnungEdited = useCallback(async (abrechnungsId, update) => {
    try {
      let abrechnung = abrechnungen.data.find(abrechnung => abrechnung.id === abrechnungsId)
      await backend.abrechnungen.updateId(abrechnungsId, {...abrechnung, ...update})
      props.queryClient.invalidateQueries("abrechnungen")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerAbrechnungEdited)
    }
  }, [props, backend, abrechnungen.data])
  
  const buchhaltungEdited = useCallback(async (buchhaltungsId, value) => {
    try {
      let update = buchhaltung.data.find(buchhaltung => buchhaltung.id === buchhaltungsId)
      update["einträge"] = value
      await backend.buchhaltung.updateId(buchhaltungsId, update)
      props.queryClient.invalidateQueries("buchhaltung")
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerBuchhaltungEdited)
    }
  }, [props, backend, buchhaltung])
  
  if (!initialized) {
    return <h2>Lade...</h2>
  }
  return (
      <div className="Carsharing">
        <Tabs selectedIndex={tabIndex} onSelect={tabIndexSelected}>
          <TabList className="Tableiste">
            <Tab>
              <AiOutlineCalendar/>
              <div>{strings.tabBuchen}</div>
            </Tab>
            <Tab>
                {!isOffice() && <TbRoad/>}
                {isOffice() && <BsDoorOpen/>}
              <div>{strings.tabFahrten}</div>
            </Tab>
            {(isOffice() && !isAdmin()) &&
              <Tab className="hidden"/>}
            {(!isOffice() || isAdmin()) &&
              <Tab>
                <AiOutlineCar />
                <div>{strings.tabFuhrpark}</div>
              </Tab>}
            {isAdmin() &&
              <Tab>
                <TbReportMoney/>
                <div>{strings.tabAbrechnung}</div>
              </Tab>}
            {!isAdmin() &&
              <Tab className="hidden"/>}
            <Tab>
              <AiOutlineLogout/>
              <div>{strings.tabLogout}</div>
            </Tab>
          </TabList>
          <TabPanel>
            <Buchen
              currentDateTime={new Date(dateTime.data)}
              buchungen={buchungen.data.filter(buchung => buchung.beendet === "")}
              fahrzeuge={fahrzeuge.data.filter(fahrzeug => fahrzeug.gelöscht === null)}
              onSubmit={buchungSubmitted} />
          </TabPanel>
          <TabPanel>
            <Fahrten
              currentDateTime={new Date(dateTime.data)}
              buchungen={buchungen.data}
              fahrzeuge={fahrzeuge.data}
              username={props.username}
              stornierenClicked={stornierenClicked}/>
          </TabPanel>
          <TabPanel>
            <Fuhrpark
              fahrzeuge={fahrzeuge.data}
              kosten={kosten.data}
              username={props.username}
              neuesFahrzeugClicked={neuesFahrzeugClicked}
              neueKostenClicked={neueKostenClicked}
              kostenLöschenClicked={kostenLöschenClicked}
              fahrzeugLöschenClicked={fahrzeugLöschenClicked}
              fahrzeugWiederherstellenClicked={fahrzeugWiederherstellenClicked}
              fahrzeugEdited={fahrzeugEdited}/>
          </TabPanel>
          <TabPanel>
            <Abrechnungen
              abrechnungen={abrechnungen.data}
              dateTime={new Date(dateTime.data)}
              buchhaltung={buchhaltung.data}
              fahrzeuge={fahrzeuge.data}
              buchungen={buchungen.data}
              kosten={kosten.data}
              kostenstellen={kostenstellen.data}
              neueKostenstelleClicked={neueKostenstelleClicked}
              kostenstelleEdited={kostenstelleEdited}
              neueAbrechnungClicked={neueAbrechnungClicked}
              abrechnungLöschenClicked={abrechnungLöschenClicked}
              abrechnungErstellenClicked={abrechnungErstellenClicked}
              abrechnungEdited={abrechnungEdited}
              buchhaltungEdited={buchhaltungEdited}/>
          </TabPanel>
          <TabPanel/>
        </Tabs>
      </div>)
}

